body {
  background-color: #080F1F;
  color: #00e5ff;
  font-family: "Darker Grotesque";
  word-wrap: break-word;
}

p {
  word-wrap: break-word;
}


.ProfilePic {
  border-radius: 50%;
  width: 18.75em;
  height: 18.75em;
  margin: 0;
  box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.25);
}


.ContactIcon {
  transform: scale(2);
  color: #AEFF00;
}
.SkillsIcon {
  transform: scale(4);
  color: #AEFF00;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //border-radius: 50%;
  //margin:50px;
  //box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.25);
}
.EducationFlexBox {
  display: flex; /* or inline-flex */
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  gap: 0.625em;
  word-wrap: break-word;

}
.ContactFlexBox {
  display: flex; /* or inline-flex */
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  gap: 0.625em 1.25em;
  word-wrap: break-word;
  margin-top: 0.625em;
}
.ContactBox {
  width: 4.375em;
  height: 4.375em;
  display: flex;
  align-items: center;
  justify-content: center;

  // background: radial-gradient(
  //   120.31% 335.94% at 50% 50%,
  //   #708ace 0%,
  //   rgba(17, 19, 22, 0) 100%
  // );
  border: 3px solid #AEFF00;
  //box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
}

.SkillsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
  padding: 10px;
  // background: radial-gradient(
  //   120.31% 335.94% at 50% 50%,
  //   #708ace 0%,
  //   rgba(17, 19, 22, 0) 100%
  // );

  //box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
}

.globalUseContainer {
  display: grid;
  place-items: center;
}

.profileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 0.625em 1.25em;
  flex-wrap: wrap;
  margin-top: center;
  justify-content: center;
}
.SkillsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 0.625em 1.25em;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 100px;
}

.EducationContainer {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  align-content: center;
  gap:  2em;
  flex-wrap: wrap;
  justify-content: center;
  padding: 100px;
}

.typewriter {
  font-weight: bold;
  font-size: 2em;
  position: relative;
}

@keyframes scrollLineAppear {
  0% {
    height: 0em;
  }
  100% {
    bottom: 12.5em;
  }
}

.typewriterCursor {
  font-size: 2em;
  color: #00e5ff;
}

.toolTip {
  display: flex;
  align-items: center;
  justify-content: center;
  //border-bottom: 1px dotted black;
}

.toolTip .toolTiptext {
  visibility: hidden;
  width: 7.5em;
  background-color: #171a21;
  color: #00e5ff;
  text-align: center;
  border-radius: 0.375em;
  padding: 0.3125em 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -3.75em;
}

.toolTip:hover .toolTiptext {
  visibility: visible;
}

.douglasPic {
  //size: 50px;
  height: 4.75em;
  margin-right: 5px;
}

.ubcPic {
  //size: 100px;
  height: 5em;
  margin-right: 5px;
  margin-left: 25px;
}

.largeText {
  font-size: 2em;
}
.verticleLine {
  height: 5em;
  width: 5px;
  background-color: #AEFF00;
  animation-name: scrollLineAppear;
  animation-duration: 1s;
}

.Experiencenheading {
  display: grid;
  place-items: left;
  margin: 2%;
  font-size: 4.25em;
}
.experienceDiv {
  display: flex; /* or inline-flex */
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  gap: 1.25em;
  justify-content: center;
}
.experienceCard {
  background-color: #046E8F;
  align-content: center;
  text-align: center;
  width: 300px;
  padding: 30px;
  transition: 0.3s;
}
.experienceCard:hover {
  background-color: #1f2833;
  align-content: center;
  text-align: center;
}
.experienceDetail {
  text-align: left;
}
.experiencePic {
  height: 100px;
  width: auto;
}


.ProjectHeading {
 float:left;
  font-size: 1.5em;
}

.ProjectDiv {
  display: flex;
  /* or inline-flex */
  flex-direction: row; 
  flex-wrap: wrap;
  align-content: center;
  gap: 1.25em;
}

.ProjectCard {
  background-color: #086788;
  width: 300px;
  padding: 30px;
  transition: 0.3s;
}

.ProjectCard:hover {
  background-color: #1f2833;
}


.ProjectPic {
  float:right;
  //transform: scale(4);
  color:#AEFF00;
}



ul {
  display: "contents";
}
.navigation {
  position: fixed !important;
  margin: auto;
  padding-top: 15px;
  box-shadow: 0px 0px 8px 0px;
  -webkit-box-shadow: 0px 0px 8px 0px;
  -moz-box-shadow: 0px 0px 8px 0px;
  background-color: #1f2833;
  width: 350px;
  z-index: 100;
  text-align: center;
}

.navTab {
  color: #00e5ff;
  text-decoration: none;
}

.navTab:hover {
  color: #fff;
}

.is-current {
  color: #AEFF00;
  text-decoration: none;
}
._X0nwb {
  background-color: #fff;
}